declare global {
  interface Window {
    reload: () => void
    config: { [k: string]: string }
  }
}

///////////////////////////////////////////////////////////////////////////////
//// Private Env
///////////////////////////////////////////////////////////////////////////////
// TODO: Validate all env vars with a zod type
export const SECONDS_PER_BLOCK = process.env.SECONDS_PER_BLOCK
export const ALGOLIA_ENV_PREFIX = process.env.ALGOLIA_ENV_PREFIX
export const ALCHEMY_API_KEY = process.env.ALCHEMY_API_KEY
export const LD_ACCOUNT_ID = process.env.LD_ACCOUNT_ID

export const APP_URL = process.env.APP_URL
export const LEGACY_API_URL = process.env.LEGACY_API_URL
export const FORCE_WEB_CLOUD_TRACING = process.env.FORCE_WEB_CLOUD_TRACING
export const JWT_SECRET = process.env.JWT_SECRET
export const GQL_API_URL = process.env.GQL_API_URL
export const GQL_API_KEY = process.env.GQL_API_KEY
export const DAPP_URL = process.env.DAPP_URL
export const COLDIE_AUCTION_LENGTH = process.env.COLDIE_AUCTION_LENGTH
export const REDIS_HOST = process.env.REDIS_HOST
export const REDIS_PORT = process.env.REDIS_PORT
export const PINATA_GATEWAY = process.env.PINATA_GATEWAY || ""
export const PROTOCOL_API_URL = process.env.PROTOCOL_API_URL
export const ALLOWLIST_BUCKET_NAME = process.env.ALLOWLIST_BUCKET_NAME || ""
export const SERIES_THUMBNAIL_BUCKET = process.env.SERIES_THUMBNAIL_BUCKET || ""
export const AVATAR_BUCKET = process.env.AVATAR_BUCKET || ""
export const BACKEND_PIPELINES_URL = process.env.BACKEND_PIPELINES_URL || ""
export const AUTH_SERVICE = process.env.AUTH_SERVICE
export const IMGIX_KEY = process.env.NEXT_PUBLIC_IMGIX_KEY || ""
export const IMGIX_SRC = process.env.NEXT_PUBLIC_IMGIX_SRC || "pixura.imgix.net"
export const PINATA_KEY = process.env.PINATA_KEY || ""
export const INDEXER_URL = process.env.INDEXER_URL || ""
export const CLAIM_SERVICE = process.env.CLAIM_SERVICE || ""
export const CLAIM_ID = process.env.CLAIM_ID || ""
export const FILEBASE_KEY = process.env.FILEBASE_KEY || ""
export const FILEBASE_SECRET = process.env.FILEBASE_SECRET || ""
export const FILEBASE_BUCKET = process.env.FILEBASE_BUCKET || ""
export const FILEBASE_GATEWAY = process.env.FILEBASE_GATEWAY || ""
export const IPFS_UPLOAD_BUCKET = process.env.IPFS_UPLOAD_BUCKET || ""
export const FRAUD_SERVICE_URL = process.env.FRAUD_SERVICE_URL || ""

///////////////////////////////////////////////////////////////////////////////
//// Public Env
///////////////////////////////////////////////////////////////////////////////
// NOTE: Always default public vars to the env var name prefixed with `REPLACE_ME_`.
// These are replaced at runtime to the env var value to work in GCR.

///////////////////////////////////////////////
// NEXT_PUBLIC_ALGOLIA_APP_ID
///////////////////////////////////////////////
export const ALGOLIA_APP_ID =
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ||
  "D27T7J41OM"

///////////////////////////////////////////////
// NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
///////////////////////////////////////////////
export const ALGOLIA_SEARCH_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY ||
  "879cb219411424f5f283980a3945b8fa"

///////////////////////////////////////////////
// NEXT_PUBLIC_PRISMIC_KEY
///////////////////////////////////////////////
export const PRISMIC_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN ||
  "MC5aZ1F5NlJJQUFDTUF1QTlo.JRnvv71deu-_ve-_ve-_vQvvv70P77-9DW0h77-977-9Mynvv73vv71577-977-977-9Pe-_vVxN77-977-9Vg"

///////////////////////////////////////////////
// NEXT_PUBLIC_HUBSPOT_API
///////////////////////////////////////////////
export const HUBSPOT_API =
  process.env.NEXT_PUBLIC_HUBSPOT_API || "pat-na1-3d3fdfe9-fb77-4d3b-bb55-378716ff9a2a"

///////////////////////////////////////////////
// NEXT_PUBLIC_HEAP_ACCOUNT_ID
///////////////////////////////////////////////
export const HEAP_ACCOUNT_ID =
  process.env.NEXT_PUBLIC_HEAP_ACCOUNT_ID ||
  "742849998"

///////////////////////////////////////////////
// NEXT_PUBLIC_WC_PROJECT_ID
///////////////////////////////////////////////
export const WC_PROJECT_ID =
  process.env.NEXT_PUBLIC_WC_PROJECT_ID ||
  "19879ed5e11e93db5261b789fd564e99"

///////////////////////////////////////////////
// NEXT_PUBLIC_MUX_URL
///////////////////////////////////////////////
export const MUX_URL =
  process.env.NEXT_PUBLIC_MUX_URL || "REPLACE_ME_NEXT_PUBLIC_MUX_URL"

///////////////////////////////////////////////
// NEXT_PUBLIC_CHAIN_ID
///////////////////////////////////////////////
const RAW_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID
  ? process.env.NEXT_PUBLIC_CHAIN_ID
  : "1"
export const CHAIN_ID = parseInt(RAW_CHAIN_ID)

///////////////////////////////////////////////
// NEXT_PUBLIC_IPFS_GATEWAY
///////////////////////////////////////////////
export const IPFS_GATEWAY =
  process.env.NEXT_PUBLIC_IPFS_GATEWAY || "REPLACE_ME_NEXT_PUBLIC_IPFS_GATEWAY"

///////////////////////////////////////////////
// NEXT_PUBLIC_ETHERSCAN_URL
///////////////////////////////////////////////
export const ETHERSCAN_URL =
  process.env.NEXT_PUBLIC_ETHERSCAN_URL ||
  ""

///////////////////////////////////////////////
// NEXT_PUBLIC_API_URL
///////////////////////////////////////////////
export const API_URL =
  process.env.NEXT_PUBLIC_API_URL || ""

///////////////////////////////////////////////
// AIRSTACK_API_URL
///////////////////////////////////////////////
export const AIRSTACK_API_URL =
  process.env.AIRSTACK_API_URL || "REPLACE_ME_AIRSTACK_API_URL"

///////////////////////////////////////////////
// AIRSTACK_API_KEY
///////////////////////////////////////////////
export const AIRSTACK_API_KEY =
  process.env.AIRSTACK_API_KEY || "15b6b0d8ce4b342e5b1a369d3682db88c"

///////////////////////////////////////////////
// Google Analytics Tracking ID
///////////////////////////////////////////////
export const GA_TRACKING_ID =
  process.env.GA_TRACKING_ID || "G-BM13EB90M0"

///////////////////////////////////////////////
// ALCHEMY_API_KEY_MAINNET
///////////////////////////////////////////////
export const ALCHEMY_API_KEY_MAINNET =
  process.env.NEXT_PUBLIC_ALCHEMY_API_KEY_MAINNET ||
  "LmCRx-2ZnRfCbQJt1ZmpmoVfJYWAPZpn"
///////////////////////////////////////////////
// ALCHEMY_API_KEY_SEPOLIA
///////////////////////////////////////////////
export const ALCHEMY_API_KEY_SEPOLIA =
  process.env.NEXT_PUBLIC_ALCHEMY_API_KEY_SEPOLIA ||
  "lPsrk2sgJwltICIEWO57PRABOoKtpcDc"
