import { Address } from "@/utils/common.types"
import { abi } from "../abis/SuperRareBazaar"

///////////////////////////////////////////////////////////////////////////////
// Addresses
///////////////////////////////////////////////////////////////////////////////
export const MAINNET_SUPERRARE_BAZAAR =
  "0x6d7c44773c52d396f43c2d511b81aa168e9a7a42"
export const SEPOLIA_SUPERRARE_BAZAAR =
  "0xc8edc7049b233641ad3723d6c60019d1c8771612"

///////////////////////////////////////////////////////////////////////////////
// getSuperRareBazaar
///////////////////////////////////////////////////////////////////////////////
export function getSuperRareBazaar(chainId: number): Address {
  if (chainId === 1) {
    return Address.parse(MAINNET_SUPERRARE_BAZAAR)
  }
  if (chainId === 11155111) {
    return Address.parse(SEPOLIA_SUPERRARE_BAZAAR)
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// SuperRareBazaarAbi
///////////////////////////////////////////////////////////////////////////////
export const SuperRareBazaarAbi = abi
