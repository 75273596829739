import { Address as ViemAddress, getAddress, isAddress, isHex } from "viem"
import { z } from "zod"

///////////////////////////////////////////////////////////////////////////////
// Address
///////////////////////////////////////////////////////////////////////////////
export const addressSchema = z
  .custom<ViemAddress>((v) => {
    const { data } = z.string().safeParse(v)
    return data && !!isAddress(data)
  })
  .transform((v) => getAddress(v))

export type Address = z.infer<typeof Address>

/**
 * @deprecated We no longer use this pattern. Use `addressSchema` instead.
 */
export const Address = addressSchema

///////////////////////////////////////////////////////////////////////////////
// BigIntish
///////////////////////////////////////////////////////////////////////////////
export const bigIntishSchema = z.union([z.string(), z.number(), z.bigint()])
export type BigIntish = z.infer<typeof bigIntishSchema>

/**
 * @deprecated We no longer use this pattern. Use `bigIntishSchema` instead.
 */
export const BigIntish = bigIntishSchema

///////////////////////////////////////////////////////////////////////////////
// Currency
///////////////////////////////////////////////////////////////////////////////
export const currencySchema = z.object({
  address: Address,
  symbol: z.string(),
})
export type Currency = z.infer<typeof currencySchema>

/**
 * @deprecated We no longer use this pattern. Use `currencySchema` instead.
 */
export const Currency = currencySchema
///////////////////////////////////////////////////////////////////////////////
// HexString
///////////////////////////////////////////////////////////////////////////////

export const hexStringSchema = z.custom<HexString>((v) => {
  return isHex(v)
})

export type HexString = `0x${string}`
