import { SuperRareV2Abi } from "@/contracts/superarrev2"
import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { SuperRareV1Abi, getSuperRareV1 } from "@/contracts/superrarev1"
import { getAddress } from "viem"
import { Address } from "./common.types"

export type MarketplaceAbis =
  | { _type: "SuperRareV1"; abi: typeof SuperRareV1Abi; contract: Address }
  | { _type: "Bazaar"; abi: typeof SuperRareBazaarAbi; contract: Address }

export type TokenAbis =
  | {
      abi: typeof SuperRareV1Abi
      _type: "SuperRareV1"
    }
  | {
      abi: typeof SuperRareV2Abi
      _type: "ERC721"
    }

export const getDefaultMarketplaceTokenAbi = (
  address: string,
  chain: number
): MarketplaceAbis => {
  if (getSuperRareV1(chain) === getAddress(address)) {
    return {
      contract: getSuperRareV1(chain),
      abi: SuperRareV1Abi,
      _type: "SuperRareV1" as const,
    }
  }
  return {
    contract: getSuperRareBazaar(chain),
    abi: SuperRareBazaarAbi,
    _type: "Bazaar",
  }
}

export type TokenAbiType = "SuperRareV1" | "ERC721"

export const getTokenAbi = (address: string, chain: number): TokenAbis => {
  if (getSuperRareV1(chain) === getAddress(address)) {
    return {
      abi: SuperRareV1Abi,
      _type: "SuperRareV1",
    }
  }
  return {
    abi: SuperRareV2Abi, // We use SRV2 since it is ERC721
    _type: "ERC721",
  }
}

export const checkIsV1Token = (address: string, chain: number): boolean => {
  return getSuperRareV1(chain) === getAddress(address)
}

export const supportsAuction = (address: string, chain: number): boolean => {
  if (checkIsV1Token(address, chain)) {
    return false
  }
  return true
}
