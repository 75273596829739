import { CHAIN_ID } from "@/constants/environment"
import { getRareToken } from "@/contracts/rare-token"
import { parseEther, zeroAddress } from "viem"

export type Currency = {
  symbol: string
  address: `0x${string}`
}

///////////////////////////////////////////////////////////////////////////////
// getRareCurrency
///////////////////////////////////////////////////////////////////////////////
export function getRareCurrency(): Currency {
  return {
    symbol: "RARE",
    address: getRareToken(CHAIN_ID),
  }
}

///////////////////////////////////////////////////////////////////////////////
// getETHCurrency
///////////////////////////////////////////////////////////////////////////////
export function getEthCurrency(): Currency {
  return {
    symbol: "ETH",
    address: zeroAddress,
  }
}

///////////////////////////////////////////////////////////////////////////////
// parseEther
///////////////////////////////////////////////////////////////////////////////

export const tryParseEther = (value: string): bigint | null => {
  try {
    return parseEther(value)
  } catch {
    return null
  }
}

///////////////////////////////////////////////////////////////////////////////
// currencyTypeByAddress
///////////////////////////////////////////////////////////////////////////////
export const currenyTypeByAddress = (address: string): string | undefined => {
  return [getEthCurrency(), getRareCurrency()].find(
    (v) => v.address === address
  )?.symbol
}

///////////////////////////////////////////////////////////////////////////////
// getAllCurrencies
///////////////////////////////////////////////////////////////////////////////
export function getAllCurrencies(): Currency[] {
  const rareCurrency = getRareCurrency()
  const ethCurrency = getEthCurrency()

  return [ethCurrency, rareCurrency]
}
