import { Erc721TokenExtended } from "@/components/cards/base"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { Currency } from "@/utils/currency"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
export type actionTypes =
  | "buy"
  | "bid"
  | "offer"
  | "price"
  | "auction"
  | "transfer"
  | "removePrice"
  | "cancelAuction"
  | "acceptOffer"
  | "settleAuction"
  | "removeOffer"
  | "send"

type TransactionModal = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  token: Erc721Token | Erc721TokenExtended | null
  setToken: (token: Erc721Token | Erc721TokenExtended | null) => void
  action: actionTypes
  setAction: (action: actionTypes) => void
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
  defaultValue: number | null
  setDefaultValue: (defaultValue: number | null) => void
  hash: `0x${string}` | undefined
  setHash: (hash: `0x${string}` | undefined) => void
  toastId: string | null
  setToastId: (toastId: string | null) => void
  currencySymbol?: Currency["symbol"] | undefined
  setCurrencySymbol?: (currencySymbol: Currency["symbol"] | undefined) => void
}

export const useTransactionModalStore = create<TransactionModal>()(
  persist(
    (set, get) => ({
      isOpen: false,
      setIsOpen: () => set({ isOpen: !get().isOpen }),
      token: null,
      setToken: (token) => set({ token }),
      action: "buy",
      setAction: (action) => set({ action }),
      approvedTerms: false,
      setApprovedTerms: () => set({ approvedTerms: !get().approvedTerms }),
      defaultValue: null,
      setDefaultValue: (defaultValue) => set({ defaultValue }),
      hash: undefined,
      setHash: (hash: `0x${string}` | undefined) => set({ hash }),
      toastId: null,
      setToastId: (toastId) => set({ toastId }),
      currencySymbol: undefined,
      setCurrencySymbol: (currencySymbol) => set({ currencySymbol }),
    }),
    {
      name: "transaction-modal-storage",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        token: state.token,
      }),
    }
  )
)
