import { abi } from "@/abis/RareToken"
import { Address } from "@/utils/common.types"
import { sepolia } from "viem/chains"

///////////////////////////////////////////////////////////////////////////////
// Addresses
///////////////////////////////////////////////////////////////////////////////
export const MAINNET_RARE_TOKEN = "0xba5bde662c17e2adff1075610382b9b691296350"
export const SEPOLIA_RARE_TOKEN = "0x197faef3f59ec80113e773bb6206a17d183f97cb"

///////////////////////////////////////////////////////////////////////////////
// getRareToken
///////////////////////////////////////////////////////////////////////////////
export function getRareToken(chainId: number): Address {
  if (chainId === 1) {
    return Address.parse(MAINNET_RARE_TOKEN)
  }
  if (chainId === sepolia.id) {
    return Address.parse(SEPOLIA_RARE_TOKEN)
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// TokenMerkleDropAbi
///////////////////////////////////////////////////////////////////////////////
export const RareTokenAbi = abi
