import { create } from "zustand"
import { persist } from "zustand/middleware"

interface EthPriceState {
  ethPrice: number
  setEthPrice: (price: number) => void
  rarePrice: number
  setRarePrice: (price: number) => void
}

const expiringStorage = {
  getItem: (key: string) => {
    const itemStr = sessionStorage.getItem(key)
    if (!itemStr) return null

    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key)
      return null
    }
    return item.value
  },
  setItem: (key: string, value: any) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + 10 * 60000, // Expires in 10 minutes
    }
    sessionStorage.setItem(key, JSON.stringify(item))
  },
  removeItem: (key: string) => {
    sessionStorage.removeItem(key)
  },
}

const useEthPriceStore = create<EthPriceState>()(
  persist(
    (set) => ({
      ethPrice: 0,
      setEthPrice: (price: number) => set({ ethPrice: price }),
      rarePrice: 0,
      setRarePrice: (price: number) => set({ rarePrice: price }),
    }),
    {
      name: "currency-price-store",
      storage: expiringStorage,
    }
  )
)

export default useEthPriceStore
