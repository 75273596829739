import BigNumber from "bignumber.js"
import { formatEther } from "viem"

export const weiToEth = (wei: number): string => {
  const number = typeof wei === "string" ? Number(wei) : wei
  const eth = number / 1e18
  return eth.toFixed(2)
}

export const formatNumber = (number: number): string => {
  if (number >= 1000000) {
    return number % 1000000 === 0
      ? `${number / 1000000}M`
      : `${(number / 1000000).toFixed(1)}M`
  } else if (number >= 1000) {
    return number % 1000 === 0
      ? `${number / 1000}K`
      : `${(number / 1000).toFixed(1)}K`
  } else if (number < 1) {
    return number.toFixed(2)
  } else {
    return number.toFixed(1)
  }
}

/**
 * Expected eth format is a string '3 ether'
 */
export const ethToDollars = (
  eth: string | number = 0,
  dollarPrice: number
): string => {
  const price = dollarPrice * (typeof eth === "string" ? parseFloat(eth) : eth)
  return formatNumber(price)
}

export const formatWei = (wei: number): string => {
  const eth = weiToEth(wei)
  const formatted = formatNumber(Number(eth))
  return formatted
}

export function ethToWei(eth: number) {
  // 1 Ether (ETH) is equal to 10^18 Wei
  const weiInEth = 10 ** 18

  // Convert ETH to Wei
  const wei = eth * weiInEth

  return wei
}

export const calculate3PercentIncrease = (amountInWei: bigint) => {
  const increaseInWei = (amountInWei * BigInt(3)) / BigInt(100)

  const newAmountInWei = amountInWei + increaseInWei

  return newAmountInWei
}

export const getTxValue = (weiPrice: number) => {
  return BigInt(
    new BigNumber(weiPrice)
      .times(1.03)
      .decimalPlaces(0, BigNumber.ROUND_FLOOR)
      .toString() as unknown as bigint
  )
}

export const getTxValueForBigInt = (weiPrice: bigint) => {
  const increaseInWei = (weiPrice * BigInt(3)) / BigInt(100)
  const newAmountInWei = weiPrice + increaseInWei
  return newAmountInWei
}

const rareAndUsd = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
) => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 2
      )
    : Number(formatEther(BigInt(amount))).toFixed(decimals || 2)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)
  return { currency, dollars }
}

export const RareUsdCurrency = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): string => {
  const { currency, dollars } = rareAndUsd(
    amount,
    usd,
    historicalUsd,
    decimals,
    withFee
  )
  return currency + " RARE" + " ($" + dollars + ")"
}

export const RareUsdCurrencyObject = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): { currency: string; dollars: string } => {
  const { currency, dollars } = rareAndUsd(
    amount,
    usd,
    historicalUsd,
    decimals,
    withFee
  )

  return {
    currency: currency + " RARE",
    dollars: "$" + dollars,
  }
}

export const EthUsdCurrency = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): string => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 3
      )
    : Number(formatEther(amount)).toFixed(decimals || 3)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)

  return currency + "Ξ" + " ($" + dollars + ")"
}

export const EthUsdCurrencyObject = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): { currency: string; dollars: string } => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 3
      )
    : Number(formatEther(BigInt(amount))).toFixed(decimals || 3)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)

  return {
    currency: currency + "Ξ",
    dollars: "$" + dollars,
  }
}
