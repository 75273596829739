import { Address } from "@/utils/common.types"
import { abi } from "../abis/SuperRareV2"

///////////////////////////////////////////////////////////////////////////////
// Addresses
///////////////////////////////////////////////////////////////////////////////
export const MAINNET_SUPERRARE_V2 = "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0"
export const SEPOLIA_SUPERRARE_V2 = "0x6c7c4879dd37bdf2b57f128b344def62da0ca34e"

///////////////////////////////////////////////////////////////////////////////
// getSuperRareV2
///////////////////////////////////////////////////////////////////////////////
export function getSuperRareV2(chainId: number): Address {
  if (chainId === 1) {
    return Address.parse(MAINNET_SUPERRARE_V2)
  }
  if (chainId === 11155111) {
    return Address.parse(SEPOLIA_SUPERRARE_V2)
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// SuperRareV2Abi
///////////////////////////////////////////////////////////////////////////////
export const SuperRareV2Abi = abi
