import { Address } from "@/utils/common.types"
import { abi } from "../abis/SuperRareV1"

///////////////////////////////////////////////////////////////////////////////
// Addresses
///////////////////////////////////////////////////////////////////////////////
export const MAINNET_SUPERRARE_V1 = "0x41a322b28d0ff354040e2cbc676f0320d8c8850d"
export const SEPOLIA_SUPERRARE_V1 = "0x0000000000000000000000000000000000000000"

///////////////////////////////////////////////////////////////////////////////
// getSuperRareV1
///////////////////////////////////////////////////////////////////////////////
export function getSuperRareV1(chainId: number): Address {
  if (chainId === 1) {
    return Address.parse(MAINNET_SUPERRARE_V1)
  }
  if (chainId === 11155111) {
    return Address.parse(SEPOLIA_SUPERRARE_V1)
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// SuperRareV1Abi
///////////////////////////////////////////////////////////////////////////////
export const SuperRareV1Abi = abi
